<template>
  <RightSideBar
    v-if="isOpen"
    :loading="isLoading"
    @close="isOpen=false"
    button-class="bg-dynamicBackBtn text-white"
    :close-button="true"
  >
    <template v-slot:title>
      <p class="text-darkPurple">
        View Details
      </p>
    </template>
    <template v-slot:subtitle>
      <div class="flex flex-col">
        <EmployeeDetails
          :employee-info="oldEmployeeInfo"
          title="EMPLOYEE DETAILS (OLD)"
        />
        <EmployeeDetails
          :employee-info="newEmployeeInfo"
          title="EMPLOYEE DETAILS (NEW)"
          :has-border="false"
        />
      </div>
    </template>
    <template v-if="Object.keys(promotionInfo).length">
      <div class="flex flex-col justify-start items-start gap-2">
        <template>
          <div class="w-full flex justify-between items-center gap-2">
            <Card class="w-1/2 flex justify-start items-start gap-2 p-3">
              <Icon icon-name="calendar" class="text-romanSilver mt-1" size="xs" />
              <div class="flex flex-col justify-start items-start">
                <h6 class="font-normal text-xs text-blueCrayola">Created</h6>
                <p class="font-semibold text-jet text-xs uppercase" v-if="promotionInfo.createdAt">
                  {{ $DATEFORMAT(new Date(promotionInfo.createdAt), "MMMM dd, yyyy") }}
                </p>
              </div>
            </Card>
            <Card class="w-1/2 flex justify-start items-start gap-2 p-3">
              <Icon icon-name="calendar" class="text-romanSilver mt-1" size="xs" />
              <div class="flex flex-col justify-start items-start">
                <h6 class="font-normal text-xs text-blueCrayola">Effective Date</h6>
                <p class="font-semibold text-jet text-xs uppercase" v-if="promotionInfo.effectiveAt">
                  {{ $DATEFORMAT(new Date(promotionInfo.effectiveAt), "MMMM dd, yyyy") }}
                </p>
              </div>
            </Card>
          </div>
        </template>
        <template>
          <div class="w-full flex justify-between items-center gap-2">
            <Card class="w-1/2 flex justify-start items-start gap-2 p-3">
              <Icon icon-name="icon-grid" class="text-romanSilver mt-1" size="xs" />
              <div class="flex flex-col justify-start items-start">
                <h6 class="font-normal text-xs text-carrotOrange">Initiated By</h6>
                <p class="font-semibold text-jet text-xs uppercase">
                 <span v-if="promotionInfo.initiatedBy">
                   {{ promotionInfo.initiatedBy.fname || '' }} {{ promotionInfo.initiatedBy.lname || '' }}
                 </span>
                 <span v-else> - </span>
                </p>
              </div>
            </Card>
            <Card class="w-1/2 flex justify-start items-start gap-2 p-3">
              <Icon icon-name="icon-grid" class="text-romanSilver mt-1" size="xs" />
              <div class="w-full flex flex-col justify-start items-start">
                <h6 class="font-normal text-xs text-flame">Approval Status</h6>
                <div class="flex justify-between w-full">
                  <p class="font-semibold text-jet text-xs uppercase">
                    {{ promotionInfo.approvalStatus }}
                  </p>
                  <div @click="priviledgedActions(promotionInfo.promotionId)">
                    <small class="text-blueCrayola cursor-pointer" style="font-size:10px">
                      View Trail
                    </small>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </template>
      </div>
    </template>
  </RightSideBar>
</template>

<script>
import Card from "@scelloo/cloudenly-ui/src/components/card"
import RightSideBar from "@/components/RightSideBar"

export default {
  components: {
    Card,
    RightSideBar,
    EmployeeDetails: () => import('./details'),
  },
  data(){
    return {
    isLoading: true,
      isOpen: false,
      oldEmployeeInfo: {},
      newEmployeeInfo: {},
      promotionInfo: {
        createdAt: '',
        effectiveAt: '',
        initiatedBy: '',
        approvalStatus: '',
      },
    }
  },
  props: {
    mode: {
      type: String,
      default: "admin",
    },
  },
  methods: {
    async priviledgedActions(requestId){
      try {
        if (this.mode === 'admin') {
          await this.$handlePrivilege('promotionHR', 'viewApprovalStatus')
        }
        this.$emit('trail', requestId)
      } catch (error) {
        const message = 'You do not have permission to perform this task'
        this.$toasted.error(message, { duration: 3000 })
      }
    },
    toggle(payload){
      this.isOpen = true

      this.oldEmployeeInfo = payload
      this.newEmployeeInfo = {
        ...payload,
        ...payload.newDetails,
        payInfo: payload.newPayInfo,
        userLevel: payload.newDetails.newDesignation?.level,
        employeeOffice: payload.newDetails.newLocation,
        userDesignation: payload.newDetails.newDesignation,
        grossPay: payload.newDetails.employee?.newAnnualSalary,
        userReportingTo: payload.newDetails.newManager
      }

      this.promotionInfo =  {
        promotionId: payload.id,
        createdAt: payload.createdAt,
        approvalStatus: payload.status,
        effectiveAt: payload.effectiveDate,
        initiatedBy: payload.newDetails.initiatedBy,
      }
      this.isLoading = false
    },
  },
};
</script>
